<template>
  <v-container fluid>
    <FacilitiesTabs
        :configurations="configurations.venue_service_configuration"
        :venue-service-id="venueServiceId"
        :venue-services="venueServices"
        @serviceChange="gotoConfiguration"
        @update:venueServiceId="(value)=>venueServiceId = value"
    />
    <v-divider class="mt-4" style="border-color: rgba(17, 42, 70, 0.14) !important;"/>

    <v-container fluid style="max-width: 85%">
      <v-form ref="conf_form">
        <div class="text-base font-semibold black-text ml-1 mt-10">Settings</div>
        <v-card class="bordered shadow-0 rounded-2 mt-2">
          <v-card-text>
            <v-row class="border-bottom" dense>
              <v-col cols="12">
                <h3 class="text-base font-semibold black-text ">Booking Based On</h3>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col md="4" sm="6" xs="12">
                <v-radio-group
                    v-model="configurations.venue_service_configuration.per_capacity"
                    :disabled="
                configurations.venue_service_configuration.is_facility_used
              "
                    class="d-flex"
                    mandatory
                    row
                    @change="changeIsCapacityEnabled"
                >
                  <v-radio :value="0" class="custom-radio" color="red" label="Time"></v-radio>
                  <v-radio :value="1" class="custom-radio" color="red" label="Capacity"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>


            <v-row class="border-bottom" dense>
              <v-col cols="12">
                <h3 class="text-base font-semibold black-text ">Booking Configuration Settings</h3>
              </v-col>
            </v-row>


            <v-row dense>

            </v-row>
            <v-row dense>
              <v-col md="2" sm="6" xs="12">
                <label for="">Minimum Age</label>
                <v-text-field
                    v-model="configurations.venue_service_configuration.min_age"
                    :rules="[
                (v) => {
                  if (v == null) return true;
                  if (!isNaN(v)) return true;
                  return 'Age should be number';
                },
              ]"
                    background-color="#fff"
                    class="q-text-field shadow-0"
                    dense
                    hide-details="auto"
                    hint="Minimum required age"
                    outlined
                    required
                ></v-text-field>
              </v-col>
              <v-col md="2" sm="6" xs="12">
                <label for="">Default Tax</label>
                <v-select
                    v-model="
                configurations.venue_service_configuration.default_tax_id
              "
                    :items="[
                { text: '5% Tax', value: 1 },
                { text: '0% Tax', value: 2 },
              ]"
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    class="q-autocomplete shadow-0"
                    dense
                    hide-details="auto"
                    hint="Max required age"
                    outlined
                    required
                ></v-select>
              </v-col>
              <v-col md="2" sm="6" xs="12">
                <label for="">Time Increment</label>
                <v-select
                    v-model="
                configurations.venue_service_configuration.time_increment
              "
                    :items="[
                { text: '5 min', value: 5 },
                { text: '10 min', value: 10 },
                { text: '15 min', value: 15 },
                { text: '20 min', value: 20 },
                { text: '30 min', value: 30 },
                { text: '40 min', value: 40 },
                { text: '45 min', value: 45 },
                { text: '1 Hour', value: 60 },
                { text: '1 Hour 30 min', value: 90 },
                { text: '2 Hour', value: 120 },
                { text: '2 Hour 30 min', value: 150 },
                { text: '3 Hour', value: 180 },
                { text: '3 Hour 30 min', value: 210 },
                { text: '4 Hour', value: 240 },
              ]"
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    class="q-autocomplete shadow-0"
                    dense
                    hide-details="auto"
                    hint="Time increment for schedule slots"
                    outlined
                    required
                    @change="changeTimeIncrement"
                ></v-select>
              </v-col>
              <v-col md="3" sm="6" xs="12">
                <label for="">Min booking time</label>

                <v-select
                    v-model="
                configurations.venue_service_configuration.min_booking_time
              "
                    :items="getMinBookingTimes()"
                    :menu-props="{ bottom: true, offsetY: true }"
                    :readonly="
                !configurations.venue_service_configuration.time_increment ||
                  configurations.venue_service_configuration.per_capacity == 1
              "
                    background-color="#fff"
                    class="q-autocomplete shadow-0"
                    dense
                    hide-details="auto"
                    hint="Min booking time"
                    item-text="text"
                    item-value="value"
                    outlined
                    required
                ></v-select>
              </v-col>

              <v-col md="3" sm="6" xs="12">
                <label for="">Max booking time</label>
                <v-select
                    v-model="
                configurations.venue_service_configuration.max_booking_time
              "
                    :items="getMinBookingTimes()"
                    :menu-props="{ bottom: true, offsetY: true }"
                    :readonly="
                !configurations.venue_service_configuration.time_increment ||
                  configurations.venue_service_configuration.per_capacity == 1
              "
                    background-color="#fff"
                    class="q-autocomplete shadow-0"
                    dense
                    hide-details="auto"
                    hint="Max booking time"
                    item-text="text"
                    item-value="value"
                    outlined
                    required
                ></v-select>
              </v-col>
              <v-col class="pb-0 pt-0" md="3" sm="6" xs="12">
                <v-switch
                    v-model="configurations.venue_service_configuration.is_public"
                    :false-value="0"
                    :label="
                (configurations.venue_service_configuration.is_public
                  ? 'Disable'
                  : 'Enable') + ' Online Booking'
              "
                    :true-value="1"
                    required
                    solo
                ></v-switch>
              </v-col>
              <v-col v-if="allow_game_formation" class="pb-0 pt-0" md="3" sm="6" xs="12">
                <v-switch
                    v-model="
                configurations.venue_service_configuration
                  .is_game_formation_enabled
              "
                    :false-value="0"
                    :label="
                (configurations.venue_service_configuration
                  .is_game_formation_enabled
                  ? 'Disable'
                  : 'Enable') + ' Game Formation'
              "
                    :true-value="1"
                    required
                    solo
                ></v-switch>
              </v-col>
              <v-col v-if="allow_surface" class="pb-0 pt-0" md="3" sm="6" xs="12">
                <v-switch
                    v-model="
                configurations.venue_service_configuration.is_surface_enabled
              "
                    :false-value="0"
                    :label="
                (configurations.venue_service_configuration.is_surface_enabled
                  ? 'Disable'
                  : 'Enable') + ' Surface'
              "
                    :true-value="1"
                    required
                    solo
                ></v-switch>
              </v-col>
              <v-col class="pb-0 pt-0" md="3" sm="6" xs="12">
                <v-switch
                    v-model="
                configurations.venue_service_configuration.is_outdoor_enabled
              "
                    :false-value="0"
                    :label="
                (configurations.venue_service_configuration.is_outdoor_enabled
                  ? 'Disable'
                  : 'Enable') + ' Outdoor/Indoor'
              "
                    :true-value="1"
                    required
                    solo
                ></v-switch>
              </v-col>
              <v-col class="pb-0 pt-0" md="3" sm="6" xs="12">
                <v-switch
                    v-model="
                configurations.venue_service_configuration.is_kiosk_enabled
              "
                    :false-value="0"
                    :label="
                (configurations.venue_service_configuration.is_kiosk_enabled
                  ? 'Disable'
                  : 'Enable') + ' Self Booking'
              "
                    :true-value="1"
                    required
                    solo
                ></v-switch>
              </v-col>
              <v-col v-if="configurations.qube_configuration && configurations.qube_configuration == 'golf'" class="pb-0 pt-0" md="3" sm="6"
                     xs="12">
                <v-switch
                    v-model="configurations.venue_service_configuration.is_golf_enabled"
                    :disabled="configurations.venue_service_configuration.is_facility_used"
                    :false-value="0"
                    :label="(configurations.venue_service_configuration.is_golf_enabled
                  ? 'Disable'
                  : 'Enable') + ' Golf Mode'
              "
                    :true-value="1"
                    required
                    solo
                    @change="changeIsGolfEnabled"
                ></v-switch>
              </v-col>

              <v-col lg="3" md="3">
                <label for="">Facility Operator Email</label>
                <v-text-field
                    v-model="
                configurations.venue_service_configuration.operator_email
              "
                    background-color="#fff"
                    class="q-text-field shadow-0"
                    dense
                    hide-details="auto"
                    outlined
                    required
                >
                  <v-tooltip slot="append" top>
                    <template v-slot:activator="{ on }">
                      <v-icon color="primary" dark v-on="on">
                        mdi-information
                      </v-icon>
                    </template>
                    <span
                    >Email provided here will receive all the related cancellation
                  and refund requests.</span
                    >
                  </v-tooltip>
                </v-text-field>
              </v-col>

              <v-col md="3" sm="6" xs="12">
                <v-switch
                    v-model="
                configurations.venue_service_configuration.auto_fill_customers
              "
                    :disabled="
                configurations.venue_service_configuration.per_capacity == 0
              "
                    false-value="0"
                    label="Auto-fill additional guest data"
                    required
                    solo
                    true-value="1"
                ></v-switch>
              </v-col>
              <v-col v-if="checkReadPermission($modules.facility.overnightBooking.slug)" class="pb-0 pt-0" md="3" sm="6"
                     xs="12">
                <v-switch
                    v-model="
                configurations.venue_service_configuration.enable_over_night_booking
              "
                    :disabled="
                configurations.venue_service_configuration.per_capacity == 1
              "
                    false-value="0"
                    label="Enable Over-night Booking"
                    required
                    solo
                    true-value="1"
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <div
            v-if="
          configurations.venue_service_configuration.is_game_formation_enabled && allow_game_formation
        "
        >
          <div class="text-base font-semibold black-text ml-1 mt-6"
          >Game Formation
          </div>
          <v-row class="mt-2" dense>
            <v-col
                v-for="(playerconfig, index) in configurations.game_formations"
                :key="`c_${index}`"
                md="3"
            >
              <v-card class="rounded-2 shadow-0 bordered" height="100" outlined>
                <v-card-text>
                  <v-tooltip
                      v-if="configurations.game_formations.length > 1"
                      bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          v-if="
                      (playerconfig &&
                        playerconfig.id &&
                        playerconfig.service_id &&
                        playerconfig.venue_service_id) ||
                        (playerconfig &&
                          !playerconfig.id &&
                          !playerconfig.service_id &&
                          !playerconfig.venue_service_id)
                    "
                          absolute
                          color="red"
                          dark
                          fab
                          icon
                          right
                          top
                          v-bind="attrs"
                          x-small
                          @click="deletePlayerConfig(index)"
                          v-on="on"
                      >
                        <DeleteIcon/>
                      </v-btn>
                    </template>
                    Delete
                  </v-tooltip>
                  <v-row dense>
                    <v-col md="6">
                      <label for="">Name</label>
                      <v-text-field
                          v-model="playerconfig.name"
                          :readonly="
                      playerconfig.id &&
                        playerconfig.service_id &&
                        !playerconfig.venue_service_id
                    "
                          background-color="#fff"
                          class="q-text-field shadow-0"
                          dense
                          hide-details="auto"
                          outlined
                          required
                      ></v-text-field>
                    </v-col>
                    <v-col md="6">
                      <label for="">Players count</label>
                      <v-text-field
                          v-model="playerconfig.players_count"
                          :readonly="
                      playerconfig.id &&
                        playerconfig.service_id &&
                        !playerconfig.venue_service_id
                    "
                          background-color="#fff"
                          class="q-text-field shadow-0"
                          dense
                          hide-details="auto"
                          outlined
                          required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3">
              <v-card
                  :style="cardStyle"
                  class="rounded-2 shadow-0 bordered d-flex align-center justify-center"
                  height="100px"
                  outlined
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="teal-color"
                        dark
                        elevation="0"
                        fab
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="addPayerConfig()"
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                  Add
                </v-tooltip>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <div v-if="configurations.venue_service_configuration.is_surface_enabled && allow_surface">
          <div class="text-base font-semibold black-text ml-1 mt-6">Facility Surface</div>
          <v-row class="mt-2" dense>
            <v-col
                v-for="(playerSurface, index) in configurations.facility_surface"
                :key="`c_${index}`"
                md="3"
            >
              <v-card class="rounded-2 shadow-0 bordered" height="100">
                <v-card-text>
                  <v-tooltip
                      v-if="configurations.facility_surface.length > 1"
                      bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          v-if="
                      (playerSurface &&
                        playerSurface.id &&
                        playerSurface.service_id &&
                        playerSurface.venue_service_id) ||
                        (playerSurface &&
                          !playerSurface.id &&
                          !playerSurface.service_id &&
                          !playerSurface.venue_service_id)
                    "
                          absolute
                          color="red"
                          dark
                          fab
                          icon
                          right
                          top
                          v-bind="attrs"
                          x-small
                          @click="deletePlayerSurface(index)"
                          v-on="on"
                      >
                        <DeleteIcon/>
                      </v-btn>
                    </template>
                    Delete
                  </v-tooltip>
                  <label for="">Surface name</label>
                  <v-text-field
                      v-model="playerSurface.name"
                      :readonly="
                  playerSurface.id &&
                    playerSurface.service_id &&
                    !playerSurface.venue_service_id
                "
                      background-color="#fff"
                      class="q-text-field shadow-0"
                      dense
                      hide-details="auto"
                      outlined
                      required
                  ></v-text-field>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col md="3">
              <v-card
                  class="pa-4 d-flex align-center justify-center rounded-2 shadow-0 bordered"
                  height="100px"
                  outlined
              >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="teal-color"
                        dark
                        elevation="0"
                        fab
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="addPayerSurface()"
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                  </template>
                  Add
                </v-tooltip>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <v-row class="mt-4">
          <v-col cols="12" lg="12" md="12">
            <div class="text-base font-semibold black-text ml-1 mt-6"
            >Quick Scan Tags
            </div>
            <v-row class="mt-2" dense>
              <v-col
                  v-for="(code, cIndex) in colorCodes"
                  :key="`index${cIndex}`"
                  cols="12"
                  lg="4"
                  md="6"
                  sm="12"
                  style="position: relative"
              >
                <v-card class="shadow-0 bordered">
                  <v-container>
                    <v-row>
                      <v-col class="pb-0" cols="12" md="12" sm="12">
                        <label for="">
                          Tag name
                        </label>
                        <v-text-field
                            v-model="code.name"
                            background-color="#fff"
                            class="q-text-field shadow-0"
                            dense
                            hide-details="auto"
                            light
                            outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6" sm="12">
                        <label for="">
                          Color Code
                        </label>
                        <v-text-field
                            v-model="code.color_code"
                            :rules="[(v) => !!v || 'Color code is required']"
                            background-color="#fff"
                            class="q-text-field shadow-0 color-picker"
                            dense
                            hide-details="auto"
                            light
                            outlined
                            required
                        >
                          <template v-slot:append>
                            <v-menu
                                :close-on-content-click="false"
                                nudge-bottom="105"
                                nudge-left="16"
                                top
                            >
                              <template v-slot:activator="{ on }">
                                <div :style="swatchStyle(cIndex)" v-on="on"/>
                              </template>
                              <v-card>
                                <v-card-text class="pa-0">
                                  <v-color-picker
                                      v-model="code.color_code"
                                      :swatches="swatches"
                                      flat
                                      show-swatches
                                  />
                                </v-card-text>
                              </v-card>
                            </v-menu>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col class="pl-0" md="4">
                        <v-switch
                            v-model="code.status_id"
                            :false-value="14"
                            :true-value="1"
                            class="mx-4 mt-7"
                            dense
                            hide-details="auto"
                            label="Active"
                            required
                        ></v-switch>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
                <v-tooltip v-if="colorCodes.length > 1" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        absolute
                        color="red"
                        dark
                        elevation="0"
                        fab
                        right
                        style="top:-5px;"
                        top
                        v-bind="attrs"
                        x-small
                        @click="deleteCode(cIndex)"
                        v-on="on"
                    >
                      <DeleteIcon/>
                    </v-btn>
                  </template>
                  Delete
                </v-tooltip>
              </v-col>
              <v-col md="3">
                <v-card
                    class="pa-4 d-flex align-center justify-center rounded-2 shadow-0 bordered"
                    height="190px"
                    outlined
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          class="teal-color"
                          dark
                          elevation="0"
                          fab
                          small
                          v-bind="attrs"
                          @click="addColorCode()"
                          v-on="on"
                      >
                        <v-icon>mdi-plus-circle</v-icon>
                      </v-btn>
                    </template>
                    Add
                  </v-tooltip>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <div v-if="
          checkWritePermission($modules.facility.management.slug) &&
            venueServices.length
        " class="text-base font-semibold black-text ml-1 mt-6">Timing Templates
        </div>
        <v-card v-if="
          checkWritePermission($modules.facility.management.slug) &&
            venueServices.length
        "
                class="rounded-2 shadow-0 bordered mt-2"
                outlined
        >
          <v-card-text>
            <v-row v-for="(timings, k) in timingTemplates" :key="k"
                   dense>
              <v-col md="3">
                <label for="">Name*</label>
                <v-text-field
                    v-model="timings.name"
                    :rules="[(v) => !!v || 'Name is required']"
                    background-color="#fff"
                    class="q-text-field shadow-0"
                    dense
                    hide-details="auto"
                    outlined
                    required
                ></v-text-field>
              </v-col>
              <v-col lg="4">
                <label for="">Choose Days Applicable</label>
                <v-autocomplete
                    v-model="timings.weekdays"
                    :items="weekdays"
                    :rules="[(v) => v.length > 0 || 'Weekdays is required']"
                    background-color="#fff"
                    class="q-autocomplete shadow-0"
                    dense
                    hide-details="auto"
                    item-text="name"
                    item-value="bit_value"
                    multiple
                    outlined
                    validate-on-blur
                >
                  <template
                      v-if="weekdays.length == timings.weekdays.length"
                      v-slot:selection="{ index }"
                  >
                    <span v-if="index == 0">All Days</span>
                  </template>
                  <template v-else v-slot:selection="{ item, index }">
                    <span v-if="index == 0">{{ item.name }}</span>
                    <span v-if="index == 1">, {{ item.name }}</span>
                    <span v-if="index == 2">, ...</span>
                  </template>

                </v-autocomplete>
              </v-col>
              <v-col lg="2">
                <label for="">From*</label>
                <v-select
                    v-model="timings.start_time"
                    :items="timingData.slice(0, (timingData.length -1))"
                    :menu-props="{ bottom: true, offsetY: true }"
                    :rules="[(v) => !!v || 'From time is required']"
                    background-color="#fff"
                    class="q-autocomplete shadow-0"
                    dense
                    hide-details="auto"
                    item-text="text"
                    item-value="value"
                    outlined
                    required
                ></v-select>
              </v-col>
              <v-col lg="2">
                <label for="">To*</label>

                <v-select
                    v-model="timings.end_time"
                    :items="timingData.slice(1)"
                    :menu-props="{ bottom: true, offsetY: true }"
                    :rules="[(v) => !!v || 'To time is required']"
                    background-color="#fff"
                    class="q-autocomplete shadow-0"
                    dense
                    hide-details="auto"
                    item-text="text"
                    item-value="value"
                    outlined
                    required
                ></v-select>
              </v-col>
              <v-col lg="1">
                <v-btn v-if="timingTemplates.length > 1"
                       class="mt-6"
                       color="red"
                       dark
                       fab
                       icon
                       x-small
                       @click="deleteTimingTemplate(k)"
                >
                  <DeleteIcon/>
                </v-btn>
              </v-col>

            </v-row>
            <v-row class="mt-4" dense>
              <v-col>
                <p class="text-base text-neon text-underline font-semibold pointer" @click="
                    timingTemplates.push({
                      name: null,
                      weekdays: [],
                      end_time: null,
                    })
                  ">+Add Timing Template</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <div class="text-base font-semibold black-text ml-1 mt-6">Documents</div>
        <v-card
            class="rounded-2 shadow-0 bordered mt-2"
            outlined

        >
          <v-card-text>
            <v-row v-for="(document, k) in configurations.venue_service_documents" :key="'d_' + k"
                   dense>
              <v-col cols="12" md="3">
                <label for="">Name</label>
                <v-text-field
                    v-model="document.name"
                    background-color="#fff"
                    class="q-text-field shadow-0"
                    dense
                    hide-details="auto"
                    outlined
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" sm="3">
                <label for="">Type</label>

                <v-select
                    v-model="document.document_type_id"
                    :items="documentTypes"
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    class="q-autocomplete shadow-0"
                    dense
                    hide-details="auto"
                    item-text="name"
                    item-value="id"
                    outlined
                ></v-select>
              </v-col>
              <v-col cols="12" md="3" sm="3">
                <label for="">
                  {{ document.id != null ? document.original_file_name : 'Document' }}

                </label>
                <v-file-input

                    v-model="document.file"
                    background-color="#fff"
                    class="q-text-field shadow-0"
                    dense
                    hide-details="auto"
                    outlined
                    prepend-icon
                    prepend-inner-icon="mdi-paperclip"
                >
                </v-file-input>
              </v-col>
              <v-col lg="2" md="2">
                <v-checkbox
                    v-model="document.allow_upload"
                    class="mt-6"
                    dense
                    false-value="0"
                    hide-details="auto"
                    label="Enable Uploads"
                    true-value="1"
                ></v-checkbox>
              </v-col>
              <v-col md="1">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-if="
                      checkDeletePermission($modules.facility.management.slug)
                    "
                        class="mt-6"
                        color="red"
                        dark
                        icon
                        v-bind="attrs"
                        x-small
                        @click="deleteDocuments(k)"
                        v-on="on"
                    >
                      <DeleteIcon/>
                    </v-btn>
                  </template>
                  Delete
                </v-tooltip>
              </v-col>
            </v-row>

            <v-row class="mt-4" dense>
              <v-col cols="12">
                <p class="text-neon text-base text-underline pointer font-semibold" @click="addDocuments">+ Add
                  Document</p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>


        <v-card class="rounded-2 shadow-0 bordered mt-8">
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <div class="d-flex justify-space-between align-center">
                  <h3 class="text-base font-semibold black-text ">Website Terms & Conditions</h3>
                  <v-switch
                      v-model="configurations.venue_service_configuration.tnc_enable"
                      dense
                      false-value="0"
                      hide-details="auto"
                      required
                      true-value="1"
                  ></v-switch>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="configurations.venue_service_configuration.tnc_enable == 1"
                   dense
            >
              <v-col cols="12">
                <text-editor
                    :message="configurations.venue_service_configuration.tnc"
                    style="width: 100%"
                    @complete="setTncContent"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <div class="text-base font-semibold black-text ml-1 mt-6">Booking Configurations</div>
        <v-card class="mb-8 rounded-2 bordered shadow-0 mt-2">
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <table class="table text-center table-bordered">
                  <thead>
                  <tr class="opacity-70 tr-neon tr-rounded text-center font-bold black-text">
                    <th class="text-center">Field Name</th>
                    <th class="text-center">View</th>
                    <th class="text-center">Mandatory</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="(config, index) in configurations.field_configurations"
                      :key="index"
                  >
                    <td class="black-text font-bold text-sm  " style="font-weight: 500 !important;">{{
                        config.name
                      }}
                    </td>
                    <td>
                      <v-btn
                          :style="
                          viewRestrictedFields.includes(config.slug) &&
                            'cursor:not-allowed;'
                        "
                          icon
                          @click="checkAsVisible(index)"
                      >
                        <TickIcon v-if="config.is_visible == 1 "/>
                        <CloseIcon v-else/>

                      </v-btn>
                    </td>
                    <td>
                      <v-btn
                          :style="
                          mandatoryRestrictedFields.includes(config.slug) &&
                            'cursor:not-allowed;'
                        "
                          icon
                          @click="checkAsMandatory(index)"
                      >
                        <TickIcon v-if="config.is_required == 1 "/>
                        <CloseIcon v-else/>

                      </v-btn>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn
            class="ma-2 white--text blue-color"
            text
            @click="configuration_dialog = false"
            >Close</v-btn
          > -->
          <v-btn
              class="ma-2 white--text blue-color"
              height="45"
              text
              @click="saveGroundSettings()"
          >Save
          </v-btn
          >
        </v-card-actions>
      </v-form>
      <confirm-model
          v-bind="confirmModel"
          @close="confirmModel.id = null"
          @confirm="confirmActions"
      ></confirm-model>
    </v-container>
  </v-container>
</template>

<script>
import constants from "@/utils/constants";
import TextEditor from "@/components/Marketing/TextEditor";
import DeleteIcon from '@/assets/images/misc/delete-bg-icon.svg';
import CloseIcon from '@/assets/images/misc/config-table-close-icon.svg';
import TickIcon from '@/assets/images/misc/config-table-tick-icon.svg';
import moment from "moment/moment";
import FacilitiesTabs from "@/views/Facility/FacilitiesTabs.vue";

export default {
  components: {
    FacilitiesTabs,
    TextEditor,
    DeleteIcon,
    CloseIcon,
    TickIcon
  },

  data() {
    return {
      allow_game_formation: false,
      allow_surface: false,
      configurations: {
        field_configurations: [],
        venue_service_documents: [],
        venue_service_configuration: {tnc_enable: 0, tnc: ""},
        deleted_service_documents: [],
        game_formations: [{name: ""}],
        facility_surface: [{}],
        qube_configuration: null
      },
      tnc: "",
      venueServiceId: null,
      viewRestrictedFields: constants.VIEWRESTRICTEDFIELDS,
      mandatoryRestrictedFields: constants.MANTATORYRESTRICTEDFIELDS,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },

      timingTemplates: [],
      deleted_timing_templates: [],
      timingData: [],
      timeIncrement: 60,
      colorCodes: [
        {
          name: null,
          status_id: 1,
          category_id: null,
          color_code: "#00AAAAFF",
        },
      ],
      swatches: [
        ["#FF0000", "#AA0000", "#550000"],
        ["#FFFF00", "#AAAA00", "#555500"],
        ["#00FF00", "#00AA00", "#005500"],
        ["#00FFFF", "#00AAAA", "#005555"],
        ["#0000FF", "#0000AA", "#000055"],
      ],
      deleted_codes: [],
    };
  },
  mounted() {
    if (this.$store.getters.getDocumentTypes.status == false) {
      this.$store.dispatch("loadDocumentTypes");
    }
    if (this.$store.getters.getWeekdays.status == false) {
      this.$store.dispatch("loadWeekdays");
    }

    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$route.params.data) {
      let data = JSON.parse(atob(this.$route.params.data));
      this.venueServiceId = data.venue_service_id;
      this.getConfiguration();
    }

  },
  computed: {
    weekdays() {
      return this.$store.getters.getWeekdays.data;
    },
    documentTypes() {
      return this.$store.getters.getDocumentTypes.data;
    },
    venueServices() {
      return this.$store.getters.getSportsService.filter((service) => service.name != "POS");
    },
  },
  methods: {
    addColorCode() {
      this.colorCodes.push({
        name: null,
        status_id: 1,
        category_id: null,
        color_code: "#00AAAAFF",
      });
      if (this.$refs.cform) {
        this.$refs.cform.resetValidation();
      }
    },
    swatchStyle(cIndex) {
      const {color_code, menu} = this.colorCodes[cIndex];
      return {
        backgroundColor: color_code,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
    deleteCode(cIndex) {
      let pkg = this.colorCodes[cIndex];
      if (pkg.name == null && pkg.color_code == null && pkg.id == null) {
        this.colorCodes.splice(cIndex, 1);
        this.$forceUpdate();
      } else {
        this.confirmModel = {
          id: cIndex,
          title: "Do you want to delete this Tag?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action?",
          type: "color_code",
        };
      }
    },
    checkAllowGameFormation() {
      let check = this.venueServices.find((ele) => ele.id === this.venueServiceId);
      let game_formation_array = ['football', 'basketball', 'multiple purpose courts', 'netball', 'padel tennis', 'table tennis', 'tennis', 'volleyball'];
      let surface_array = ['football', 'Basketball - Walk In', 'Bowling', 'Ice Rink', 'padel tennis', 'table tennis', 'tennis', 'volleyball', 'badminton'];
      this.allow_game_formation = game_formation_array.includes(check.name.toLowerCase());
      this.allow_surface = surface_array.includes(check.name.toLowerCase());
    },
    setTncContent(content) {
      this.tnc = content;
    },
    addPayerConfig() {
      this.configurations.game_formations.push({name: null});
      this.$forceUpdate();
    },

    addPayerSurface() {
      this.configurations.facility_surface.push({name: null});
      this.$forceUpdate();
    },

    deletePlayerConfig(index) {
      if (
          !this.configurations.game_formations[index].name &&
          !this.configurations.game_formations[index].id
      ) {
        this.configurations.game_formations.splice(index, 1);
        if (!this.configurations.game_formations.length) {
          this.configurations.game_formations = [{}];
        }
      } else {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this player?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "delete_player",
        };
      }
    },

    deletePlayerSurface(index) {
      if (
          !this.configurations.facility_surface[index].name &&
          !this.configurations.facility_surface[index].id
      ) {
        this.configurations.facility_surface.splice(index, 1);
        if (!this.configurations.facility_surface.length) {
          this.configurations.facility_surface = [{}];
        }
      } else {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this surface?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "delete_facility_surface",
        };
      }
    },

    checkAsMandatory(index) {
      let field = this.configurations.field_configurations[index];
      if (!this.mandatoryRestrictedFields.includes(field.slug)) {
        this.configurations.field_configurations[index].is_required =
            field.is_required == 1 ? 0 : 1;
        if (field.is_required == 1 && field.is_visible == 0) {
          this.configurations.field_configurations[index].is_visible = 1;
        }
      }
    },
    checkAsVisible(index) {
      let field = this.configurations.field_configurations[index];
      if (!this.viewRestrictedFields.includes(field.slug)) {
        this.configurations.field_configurations[index].is_visible =
            field.is_visible == 1 ? 0 : 1;
        if (field.is_required == 1 && field.is_visible == 0) {
          this.configurations.field_configurations[index].is_required = 0;
        }
      }
    },
    addDocuments() {
      this.configurations.venue_service_documents.push({
        name: null,
        file: null,
        document_type_id: null,
        allow_upload: null,
      });
    },
    deleteDocuments(index) {
      if (
          !this.configurations.venue_service_documents[index].name &&
          !this.configurations.venue_service_documents[index].id &&
          !this.configurations.venue_service_documents[index].document_type_id
      ) {
        this.configurations.venue_service_documents.splice(index, 1);
        if (!this.configurations.venue_service_documents.length) {
          this.configurations.venue_service_documents = [{}];
        }
      } else {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this custom field?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "delete_document",
        };
      }
    },
    confirmActions(data) {
      if (data.type == "delete_document") {
        if (this.configurations.venue_service_documents[data.id].id != null) {
          this.configurations.deleted_service_documents.push(
              this.configurations.venue_service_documents[data.id].id
          );
        }
        this.configurations.venue_service_documents.splice(data.id, 1);
        if (!this.configurations.venue_service_documents.length) {
          this.configurations.venue_service_documents = [{}];
        }
      }

      if (data.type == "delete_player") {
        if (this.configurations.game_formations[data.id].id != null) {
          this.configurations.deleted_game_formation.push(
              this.configurations.game_formations[data.id].id
          );
        }
        this.configurations.game_formations.splice(data.id, 1);
        if (!this.configurations.game_formations.length) {
          this.configurations.game_formations = [{}];
        }
      }

      if (data.type == "delete_facility_surface") {
        if (this.configurations.facility_surface[data.id].id != null) {
          this.configurations.deleted_facility_surface.push(
              this.configurations.facility_surface[data.id].id
          );
        }
        this.configurations.facility_surface.splice(data.id, 1);
        if (!this.configurations.facility_surface.length) {
          this.configurations.facility_surface = [{}];
        }
      }
      if (data.type == 'delete_timing_template') {
        this.deleted_timing_templates.push(this.timingTemplates[data.id].id);
        this.timingTemplates.splice(data.id, 1);
      }
      if (data.type === "color_code") {
        let index = data.id;
        if (this.colorCodes[index].id != null) {
          this.deleted_codes.push(this.colorCodes[index].id);
        }
        this.colorCodes.splice(index, 1);
        this.$forceUpdate();
      }
      this.confirmModel.id = null;
    },

    gotoConfiguration() {
      if (!this.venueServiceId) {
        this.showError("Please add new service");
        return;
      }
      this.$router.push({
        name: "FacilityConfiguration",
        params: {
          data: btoa(JSON.stringify({venue_service_id: this.venueServiceId})),
        },
      });
      this.getConfiguration();
    },
    getConfiguration() {
      this.showLoader();
      this.$store
          .dispatch("loadConfigurationsByVenueServiceId", this.venueServiceId)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.configurations = response.data.data;
              if (
                  this.configurations.venue_service_configuration &&
                  this.configurations.venue_service_configuration
                      .auto_fill_customers
              ) {
                this.configurations.venue_service_configuration.auto_fill_customers = this.configurations.venue_service_configuration.auto_fill_customers.toString();
              }
              if (
                  this.configurations.venue_service_configuration &&
                  this.configurations.venue_service_configuration
                      .enable_over_night_booking
              ) {
                this.configurations.venue_service_configuration.enable_over_night_booking = this.configurations.venue_service_configuration.enable_over_night_booking.toString();
              }
              if (
                  this.configurations.venue_service_configuration &&
                  this.configurations.venue_service_configuration.tnc_enable
              ) {
                this.configurations.venue_service_configuration.tnc_enable = this.configurations.venue_service_configuration.tnc_enable.toString();
              }
              this.tnc = this.configurations.venue_service_configuration.tnc;

              this.configurations.venue_service_documents.forEach((ele, ii) => {
                if (ele && ele.allow_upload) {
                  this.configurations.venue_service_documents[
                      ii
                      ].allow_upload = ele.allow_upload.toString();
                }
              });
              if (
                  this.configurations.venue_service_configuration &&
                  this.configurations.venue_service_configuration.allow_upload
              ) {
                this.configurations.venue_service_configuration.allow_upload = this.configurations.venue_service_configuration.allow_upload.toString();
              }

              if (this.configurations.venue_service_documents.length == 0) {
                this.configurations.venue_service_documents.push({
                  document_type_id: "",
                  name: "",
                  allow_upload: "0",
                });
              }
              this.configurations.deleted_service_documents = [];
              this.configurations.deleted_game_formation = [];
              this.configurations.deleted_facility_surface = [];
              let data = this.$store.getters.getConfigurationByVenueServiceId(
                  this.venueServiceId
              );
              this.timeIncrement =
                  data.time_increment != null ? data.time_increment : 60;


              if (this.configurations.facility_tags.length) {
                this.colorCodes = this.configurations.facility_tags;
              } else {
                this.colorCodes = [
                  {
                    name: null,
                    status_id: 1,
                    category_id: null,
                    color_code: "#00AAAAFF",
                  },
                ];
              }
              this.deleted_codes = [];

              this.generateTiming();
              this.getTimingTemplates()
              this.$forceUpdate();
              this.checkAllowGameFormation()
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
            this.hideLoader();
          });
    },

    deleteTimingTemplate(index) {
      if (this.timingTemplates[index].id) {
        this.confirmModel = {
          id: index,
          title: "Do you want to delete this templates?",
          description:
              "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
          type: "delete_timing_template",
        };
      } else {
        this.timingTemplates.splice(index, 1);
      }
    },
    saveGroundSettings() {
      if (!this.$refs.conf_form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      let formData = new FormData();
      formData.append(`venue_service_id`, this.venueServiceId);
      formData.append(`product_type`, "Facility");
      this.configurations.field_configurations.forEach((item, index) => {
        for (let key in item) {
          if (item[key] != null) {
            formData.append(
                `field_configurations[${index}][${key}]`,
                item[key]
            );
          }
        }
      });
      if (this.configurations.deleted_service_documents.length) {
        this.configurations.deleted_service_documents.forEach((item, index) => {
          formData.append(`deleted_service_documents[${index}]`, item);
        });
      }


      if (this.timingTemplates.length) {
        this.timingTemplates.forEach((template, index) => {
          for (let key in template) {
            formData.append(
                `timing_templates[${index}][${key}]`,
                typeof template[key] != "object"
                    ? template[key]
                    : JSON.stringify(template[key])
            );
          }
          formData.set(
              `timing_templates[${index}][venue_service_id]`,
              this.venueServiceId
          );
        });
      }

      if (this.deleted_timing_templates.length) {
        this.deleted_timing_templates.forEach((item, index) => {
          formData.append(`deleted_timing_templates[${index}]`, item);
        });
      }
      if (this.configurations.venue_service_documents.length) {
        this.configurations.venue_service_documents.forEach((item, index) => {
          if (item.name && item.document_type_id && item.file) {
            for (let key in item) {
              formData.append(
                  `venue_service_documents[${index}][${key}]`,
                  item[key]
              );
            }
          } else if (item.name && item.document_type_id && item.file_path) {
            for (let key in item) {
              formData.append(
                  `venue_service_documents[${index}][${key}]`,
                  item[key]
              );
            }
          }
        });
      }

      if (this.configurations.game_formations.length) {
        this.configurations.game_formations.forEach((item, index) => {
          if (item.name) {
            for (let key in item) {
              formData.append(`game_formations[${index}][${key}]`, item[key]);
            }
          }
        });
      }

      if (this.configurations.facility_surface.length) {
        this.configurations.facility_surface.forEach((item, index) => {
          if (item.name) {
            for (let key in item) {
              formData.append(`facility_surface[${index}][${key}]`, item[key]);
            }
          }
        });
      }

      if (this.configurations.deleted_facility_surface.length) {
        this.configurations.deleted_facility_surface.forEach((item, index) => {
          formData.append(`deleted_facility_surface[${index}]`, item);
        });
      }

      if (this.configurations.deleted_game_formation.length) {
        this.configurations.deleted_game_formation.forEach((item, index) => {
          formData.append(`deleted_game_formation[${index}]`, item);
        });
      }

      for (let key in this.configurations.venue_service_configuration) {
        if (key == "per_capacity") {
          formData.append(
              `venue_service_configuration[${key}]`,
              this.configurations.venue_service_configuration[key]
          );
        } else if (
            this.configurations.venue_service_configuration[key] != null &&
            this.configurations.venue_service_configuration[key] != ""
        ) {
          formData.append(
              `venue_service_configuration[${key}]`,
              this.configurations.venue_service_configuration[key]
          );
        }
      }
      if (this.configurations.venue_service_configuration.tnc_enable == 1) {
        formData.append(`venue_service_configuration[tnc]`, this.tnc);
      }

      if (this.colorCodes.length > 0) {
        this.colorCodes.forEach((item, index) => {
          formData.append(`color_codes[${index}][id]`, item.id);
          formData.append(`color_codes[${index}][name]`, item.name);
          formData.append(`color_codes[${index}][status_id]`, item.status_id);
          formData.append(`color_codes[${index}][color_code]`, item.color_code);
        });
      }
      if (this.deleted_codes.length) {
        this.deleted_codes.forEach((item, index) => {
          formData.append(`deleted_codes[${index}]`, item);
          formData.append(`deleted_codes[${index}]`, item);
        });
      }


      this.showLoader();
      this.$http({
        method: "post",
        data: formData,
        url: "venues/configurations",
        headers: {
          "Content-Type": "multipart/form-data; boundary=${form._boundary}",
        },
      })
          .then((response) => {
            if (response.status == 200) {
              this.$store.dispatch("loadVenueServices");
              this.$store
                  .dispatch(
                      "loadConfigurationsByVenueServiceId",
                      this.venueServiceId
                  )
                  .then(() => {
                    this.$nextTick(() => {
                      this.$forceUpdate;
                    });
                  });
              this.getConfiguration();
              this.hideLoader();
              this.showSuccess("Configuration successfully updated");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
            this.hideLoader();
            this.$store.dispatch(
                "loadConfigurationsByVenueServiceId",
                this.venueServiceId
            );
            this.$store.dispatch("loadVenueServices");
          });
    },
    gotoFacilities() {
      this.$router.push({
        name: "FacilityPerService",
        params: {
          data: btoa(JSON.stringify({venue_service_id: this.venueServiceId})),
        },
      });
    },

    gotoRentalTicketProducts(currentSlide) {
      if (!this.venueServiceId) {
        this.showError("Please add new service");
        return;
      }
      this.$router.push({
        name: "TickerRentalProductPerService",
        params: {
          data: btoa(
              JSON.stringify({
                venue_service_id: this.venueServiceId,
                is_ticket: currentSlide == "ticket" ? 1 : 0,
              })
          ),
        },
      });
    },
    getMinBookingTimes() {
      let times = [];
      const timeIncrement = this.configurations.venue_service_configuration
          .time_increment;
      let hour = parseInt(timeIncrement / 60);
      if (timeIncrement) {
        let index = 1;
        while (hour < 24) {
          let time = timeIncrement * index;
          hour = parseInt(time / 60);
          let min = time / 60 - hour > 0 ? (time / 60 - hour) * 60 : 0;
          let text = hour > 0 ? `${hour} Hour ` : "";
          text += min > 0 ? `${Math.round(min)} Min` : "";
          times.push({value: time, text: text});
          index++;
        }
      }
      return times;
    },
    changeTimeIncrement() {
      if (this.configurations.venue_service_configuration.per_capacity == 1) {
        this.configurations.venue_service_configuration.min_booking_time = this.configurations.venue_service_configuration.time_increment;
      }
    },
    changeIsGolfEnabled() {
      if (this.configurations.venue_service_configuration.is_golf_enabled) {
        this.configurations.venue_service_configuration.per_capacity = 1;
      }
    },
    changeIsCapacityEnabled() {
      if (!this.configurations.venue_service_configuration.per_capacity) {
        this.configurations.venue_service_configuration.is_golf_enabled = 0;
      }
    },
    getTimingTemplates() {
      this.showLoader("Loading");
      this.$store
          .dispatch("loadTimingTemplatesByVenueServiceId", this.venueServiceId)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.timingTemplates = JSON.parse(
                  JSON.stringify(response.data.data)
              );
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },

    gotoCategoryProducts() {
      if (!this.venueServiceId) {
        this.showError("Please add new service");
        return;
      }
      this.$router.push({
        name: "FacilityProductsPerService",
        params: {
          data: btoa(JSON.stringify({venue_service_id: this.venueServiceId})),
        },
      });
    },


    gotToFacilityForm() {
      if (!this.venueServiceId) {
        this.showError("Please add new service");
        return;
      }
      this.$router.push({
        name: "FacilityForm",
        params: {
          data: btoa(
              JSON.stringify({
                venue_service_id: this.venueServiceId,
                service: this.venueServices.find(
                    (item) => item.venue_service_id == this.venueServiceId
                ).name,
              })
          ),
        },
      });
    },


    generateTiming() {
      let currentTime = moment("00:00:00", "HH:mm:ss");
      this.timingData = [];
      this.timingData.push({
        value: currentTime.format("HH:mm:ss"),
        text: currentTime.format("hh:mm a"),
      });
      let hour = 0;
      while (hour < 24) {
        currentTime = currentTime.add(this.timeIncrement, "minutes");
        let data = {
          value: currentTime.format("HH:mm:ss"),
          text: currentTime.format("hh:mm a"),
        };
        if (data.value == "00:00:00") {
          data.value = "23:59:59";
          this.timingData.push(data);
          hour = 24;
        } else {
          this.timingData.push(data);
          hour = currentTime.get("hours");
        }
      }
    },
  },

};
</script>

<style>
.color-picker .v-input__append-inner {
  margin-top: 6px !important;
}
</style>

<style scoped>
.settings_table {
  width: 100%;
  text-align: center !important;
}

.settings_table th {
  text-align: center !important;
}

.settings_table td,
.settings_table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.settings_table tr {
  background-color: #f2f2f2;
}

.settings_table tbody td:first-child {
  font-weight: bolder;
  font-size: 13px;
}

.settings_table tr:hover {
  background-color: #ddd;
}

.settings_table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #066a8c;
  color: white;
}
</style>
